import React from "react"
import styles from "../Disclaimer/style.module.scss"

export default () => {
  return (
    <div className="op_block">
      <div className={styles.content}>
        <div className={styles.content_title}>Privacy Policy</div>
        <div className={styles.content_description}>
          <p>
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at Epicron Digital
            unless otherwise defined in this Privacy Policy.
          </p>
          <p>
            <b className={styles.content_description_bold}>
              Information Collection and Use
            </b>
          </p>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information. The
            information that we request will be retained by us and used as
            described in this privacy policy.
          </p>
          <div>
            <p>
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p>
              Link to privacy policy of third party service providers used by
              the app
            </p>
            <ul>
              <li>
                <a
                  className={styles.content_description_link}
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Play Services
                </a>
              </li>
              <li>
                <a
                  className={styles.content_description_link}
                  href="https://support.google.com/admob/answer/6128543?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AdMob
                </a>
              </li>
              <li>
                <a
                  className={styles.content_description_link}
                  href="https://firebase.google.com/policies/analytics"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Analytics for Firebase
                </a>
              </li>
              <li>
                <a
                  className={styles.content_description_link}
                  href="https://firebase.google.com/support/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Firebase Crashlytics
                </a>
              </li>
              <li>
                <a
                  className={styles.content_description_link}
                  href="https://www.facebook.com/about/privacy/update/printable"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
          <p>
            <b className={styles.content_description_bold}>Log Data</b>
          </p>
          <p>
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>
          <p>
            <b className={styles.content_description_bold}>Cookies</b>
          </p>
          <p>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly. However, the
            app may use third party code and libraries that use “cookies” to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device. If you choose to refuse our cookies, you
            may not be able to use some portions of this Service.
          </p>
          <p>
            <b className={styles.content_description_bold}>Service Providers</b>
          </p>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service</li>
            <li>To provide the Service on our behalf</li>
            <li>To perform Service-related services, or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
          <p>
            <b className={styles.content_description_bold}>Security</b>
          </p>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <p>
            <b className={styles.content_description_bold}>
              Links to Other Sites
            </b>
          </p>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we bly advise you
            to review the Privacy Policy of these websites. We have no control
            over and assume no responsibility for the content, privacy policies,
            or practices of any third-party sites or services.
          </p>
          <p>
            <b className={styles.content_description_bold}>
              Children’s Privacy
            </b>
          </p>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
          <p>
            <b className={styles.content_description_bold}>
              Collection of Account Information
            </b>
          </p>
          <p>
            Our application on the Google Play Store may collect the following
            account information: UserId, Name, and Email. This data is collected
            for the following purposes:
          </p>

          <ul>
            <li>
              <p>
                Developer Communication: We may use the collected account
                information to communicate with users regarding application
                updates, improvements, and important announcements.
              </p>
            </li>
            <li>
              <p>
                Fraud Detection: The collected account information may be
                utilized to detect and prevent fraudulent activities, ensuring
                the security of our application and protecting users' interests.
              </p>
            </li>
            <li>
              <p>
                Personalization of Account: The account information collected
                helps us personalize the user experience within our application,
                providing tailored content, recommendations, and features that
                match individual preferences.
              </p>
            </li>
          </ul>
          <p>
            <b className={styles.content_description_bold}>
              Non-Sharing of Account Information
            </b>
          </p>
          <p>
            We assure you that the collected account information, including
            UserId, Name, and Email, will not be shared with any third party. We
            are committed to maintaining the confidentiality and privacy of our
            users' data.
          </p>

          <p>
            <b className={styles.content_description_bold}>
              Account Data Retention and Deletion
            </b>
          </p>
          <p>
            The collected account information will be retained for a period of
            90 days from the date of collection. After this period, the data
            will be automatically deleted from our systems. Users also have the
            option to request the deletion of their account information by
            contacting the developer via email. Upon receiving a deletion
            request, we will promptly delete the relevant data from our records.
          </p>

          <p>
            Please note that by using our application, you agree to the terms
            mentioned above regarding the collection, usage, retention, and
            deletion of your account information. We encourage you to review our
            complete Privacy Policy for more detailed information regarding data
            handling and protection.
          </p>

          <p>
            If you have any concerns or inquiries about our privacy practices,
            please feel free to contact us via email at{" "}
            <a
              className={styles.content_description_link}
              href="mailto:epicrondigital@gmail.com"
              target="_blank"
            >
              epicrondigital@gmail.com
            </a>
          </p>
          <p>
            <b className={styles.content_description_bold}>
              Changes to This Privacy Policy
            </b>
          </p>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </p>
        </div>
      </div>
    </div>
  )
}
